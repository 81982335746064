<template>
    <div class="error-container">        
        <div id="tm-top" class="tm-top">
            <div class="text-center">
                <section>
                    <div class="container">
                        <div class="row">
                            <div class="modal-heading">
                                <img class="img-control" id="riskprologo" alt="Logo" src="/src/assets/images/riskpro.png">
                            </div>
                        </div>
                        <div class="row">
                            <div class="modal-heading">
                                <div><h1 class="title">{{error.title}}{{error.status}}</h1></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="modal-heading">
                                <div> 
                                    <h4 class="detail">
                                        <span>{{error.code}}: </span>
                                        <span>{{error.message}}</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>
<script>    
import queryString from 'query-string';
    module.exports = {
        data: function () {
            return {
                error: {
                    title: 'Error: ',
                    message: 'You are Unauthorized',
                    code: '',
                    status: ''
                }
            };
        },
        mounted: function () {
            this.$nextTick(() => {
                const parsedHash = queryString.parse(window.location.search);
                this.error.message =  parsedHash.detail;
                this.error.code = parsedHash.code;
                this.error.status = parsedHash.status;
            })
        },
        methods: {
        }
    };
</script>
