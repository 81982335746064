var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { attrs: { id: "top" } }, [
      _c("div", { staticClass: "text-center" }, [
        _c("section", [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-4 login p4-error" }, [
                _c("div", { staticClass: "modal-content" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-body" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "login__errors text-danger text-bold p4-error-text"
                      },
                      [
                        _vm._v(
                          "\n                                        " +
                            _vm._s(_vm.error) +
                            "\n                                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(2)
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tm-hero", attrs: { id: "hero" } }, [
      _c("section", { staticClass: "hero-section" }, [
        _c("div", { staticClass: "hero-bg" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-heading" }, [
      _c("img", {
        attrs: {
          id: "riskprologo",
          alt: "Logo",
          src: "/src/assets/images/riskpro.png"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fs-lg-0-9 mt-5 help-text text-muted" }, [
      _vm._v("\n                                        Please "),
      _c("a", { attrs: { href: "/p4login" } }, [_vm._v("try again")]),
      _vm._v(
        ". If you need assistance, contact RiskPro at (949) 207-1044\n                                        or email us at "
      ),
      _c("a", { attrs: { href: "mailto:support@riskproadvisor.com" } }, [
        _vm._v("support@riskproadvisor.com")
      ]),
      _vm._v(".\n                                    ")
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-005001cc", { render: render, staticRenderFns: staticRenderFns })
  }
}