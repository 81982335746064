<template>
    <div class="loader" v-if="showLoader === true">
        <div class="loader-content"></div>
    </div>
</template>
<script>
    module.exports = {
        name: 'loader',
        props: {
            showLoader: {
                type: Boolean,
                default: false
            }
        },
        methods : {
            checkIsIe9() {
                let isIe9 = false;
                if (/MSIE (\d+\.\d+);/.test(navigator.userAgent)) {
                let ieversion = Number(RegExp.$1);
                    if (ieversion === 9) {
                        isIe9 = true;
                    }
                }
                return isIe9;
            },
            setLoaderInterval(status) {
                let interval;
                if(status === 'set') {
                    interval = setInterval(function (){
                        $({deg: 0}).animate({ deg: 360 }, {
                            duration: 1000,
                            step: function(now){
                                $('.loader .loader-content').css({
                                    "transform" : "rotate(" + now + "deg)",
                                    "-ms-transform" : "rotate(" + now + "deg)"
                                });
                            }
                        });
                    }, 1000);
                } else {
                    clearInterval(interval);
                }
            }
        },
        watch: {
            showLoader(newVal) {
                if(this.checkIsIe9() && newVal){
                    this.setLoaderInterval('set');
                } else {
                    this.setLoaderInterval('clear');
                }
            }
        }
    };
</script>
