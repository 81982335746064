<template>
    <div class="forgot-password-container">
        <div id="tm-hero" class="tm-hero ">
            <section>
                <div class="hero-bg"></div>
            </section>
        </div>
        <div id="tm-top" class="tm-top">
            <div class="text-center">
                <section>
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-4 forgot-password">
                                <div class="modal-content">
                                    <div class="modal-heading">
                                        <img id="riskprologo" alt="Logo" src="/src/assets/images/riskpro.png">
                                    </div>
                                    <div class="modal-body mt-4" v-if="succeed === false">
                                        <form role="form" class="text-left" method="post"
                                              v-on:submit.prevent="onSubmit">
                                            <div class="text-center fs-lg-0-9">
                                                Please enter your User ID and Firm ID to reset your password.
                                            </div>
                                            <div class="forgot-password__errors text-danger text-bold mt-2">
                                                {{error}}
                                            </div>
                                            <div class="form-group label-floating mt-5"
                                                 v-bind:class="{ 'has-error' : required_user}">
                                                <input id="user_name" ref="username" class="form-control" type="text"
                                                       name="user name"
                                                       value="" v-model="user_name" @keyup="onChangeRemoveError()">
                                                <label class="control-label" for="user_name">Enter User ID</label>
                                                <div v-if="required_user" class="text-danger">User ID should not be
                                                    blank.
                                                </div>
                                            </div>
                                            <div class="form-group label-floating"
                                                 v-bind:class="{ 'has-error' : required_firm_id}">
                                                <input id="firmid" ref="firmid" class="form-control" type="text"
                                                       name="firmid"
                                                       value="" v-model="firm_id" @keyup="onChangeRemoveError()">
                                                <label class="control-label" for="firmid">Enter Firm ID</label>
                                                <div v-if="required_firm_id" class="text-danger">Firm ID should not be
                                                    blank.
                                                </div>
                                            </div>
                                            <div class="fs-lg-0-9 mt-5 help-text text-muted">
                                                Need help with a User ID or Password? Contact RiskPro at (949) 207-1044
                                                or email us at <a href="mailto:support@riskproadvisor.com">support@riskproadvisor.com</a>.
                                            </div>
                                            <div class="modal-footer">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <button type="submit"
                                                                class="btn btn-raised btn-primary btn-lg w-100">SUBMIT
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="modal-body mt-4" v-else>
                                        <div class="text-center fs-lg-0-9">
                                            Please check your email for the reset password link.
                                        </div>
                                        <div class="text-cenetr mt-5">
                                            Password reset information sent to:
                                            <div class="font-weight-bold mt-2 text-muted">{{sentEmail}}</div>
                                        </div>
                                        <div class="text-center fs-lg-0-9 mt-5 pt-5 help-text">
                                            <div class="mb-5 font-weight-bold">
                                                <a href="/">RETURN TO LOGIN</a>
                                            </div>
                                            <div class="text-muted text-left">
                                                Need help with a User ID or Password? Contact RiskPro at (949) 207-1044 or
                                                email us at <a href="mailto:support@riskproadvisor.com">support@riskproadvisor.com</a>.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <loader :showLoader="loading"></loader>
    </div>
</template>
<script>
const axios = require('axios');

module.exports = {
    data() {
        return {
            user_name: '',
            firm_id: '',
            succeed: false,
            forgot_api_endpoint: '/api/v1/forgot_password',
            loading: false,
            required_user: false,
            required_firm_id: false,
            sentEmail: '',
            error: ''
        };
    },
    methods: {
        onSubmit() {
            const csrf = this.$cookie.get('general-token');
            const me = this;
            if (me.user_name === '') {
                me.required_user = true;
                this.$refs.username.focus();
                return;
            }
            if (me.firm_id === '') {
                me.required_firm_id = true;
                this.$refs.firmid.focus();
                return;
            }
            this.loading = true;
            axios.post(
                this.forgot_api_endpoint, {
                    user_name: this.user_name,
                    firm_no: this.firm_id
                },
                {
                    headers: {
                        'csrf-token': csrf
                    }
                }
            )
                .then((res) => {
                    me.loading = false;

                    if (res.data.status === 'Success') {
                        me.succeed = true;
                        me.sentEmail = res.data.response.detail.email;
                        me.changeEmailFormat(me.sentEmail);
                    }
                })
                .catch((err) => {
                    me.loading = false;
                    me.error = err.response.data.error || err.response.data.message || err.response.data.response.detail || err.response.statusText;
                });
        },
        onChangeRemoveError() {
            if (this.user_name !== '') {
                this.required_user = false;
            }
            if (this.firm_id !== '') {
                this.required_firm_id = false;
            }
        },
        changeEmailFormat(email) {
            const arr = email.split('');
            const newEmail = [];
            const atIndex = arr.indexOf('@') + 1;
            for (let i = 0; i < arr.length; i++) {
                if (i === 0 || arr[i] === '@' || i === atIndex || i > (arr.length - 5)) {
                    newEmail.push(arr[i]);
                } else {
                    newEmail.push('*');
                }
            }
            this.sentEmail = newEmail.join('');
        }
    }
};
</script>
