<template>
    <div class="text-center">
        <h3 class="text-success" v-show="!error">Redirecting...</h3>
        <h3 class="text-warning" v-show="error">{{ error }}</h3>
        <form id="redirectForm" :action="redirectUrl" target="_self" method="POST">
            <input id="jwtToken" type="hidden" name="jwt_token" :value="jwtToken">
            <input id="remember_me" type="hidden" name="remember_me" :value="remember_me">
        </form>
    </div>
</template>

<script>
    const { getInstance } = require('../../auth');

    module.exports = {
        data() {
            return {
                error: '',
                jwtToken: '',
                redirectUrl: '',
                remember_me: true
            };
        },
        mounted() {
            this.init(this.handleSSOLogin);
        },
        methods: {            
            init(fn) {
                let instance = getInstance();
                instance.$watch("loading", loading => {
                    if (loading === false) {
                        fn(instance);
                    }
                });
            },
            async handleSSOLogin(authInstance) {
                const queryParam = this.getUrlParameter('token');
                const authParam = JSON.parse(queryParam);
                if (authParam.hasOwnProperty('response')) {
                    if (authParam.status !== 'Fail') {
                        let details = authParam.response.detail;
                        // check if user is P4 then redirect to P4 login page
                        const decodedToken = this.decodeJwt(details.access_token);
                        const roleType = decodedToken.user_session_detail.roles.filter(v => v.current_role === 1);
                        /* if ((roleType[0].p4_role === 3 || roleType[0].p4_role === 2) && !authInstance.isAuthenticated) {
                         *     // Redirect to Auth0 login page only if user is P4 AND session does not exist
                         *     authInstance.loginWithRedirect();
                         * } else {
                         *     if (authInstance.isAuthenticated) {
                         *         // If session exists while coming from Orion or ENV, then forward refreshToken to RPA
                         *         const refreshToken = await authInstance.getRefreshToken();
                         *         details = {
                         *             ...details,
                         *             auth0_refresh_token: refreshToken
                         *         }
                         *     } */
                        console.log(authInstance);
                        console.log(roleType);
                            this.jwtToken = JSON.stringify(details);
                            this.redirectUrl = authParam.response.detail.pk_redirection_url;
                            setTimeout(() => {
                                document.getElementById('redirectForm').submit();
                            }, 1);
                        /* } */
                    } 
                }
            },
            getUrlParameter(name) {
                 // eslint-disable-line no-prototype-builtins
                const nameParam = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
                const regex = new RegExp('[\\?&]' + nameParam + '=([^&#]*)');
                const results = regex.exec(window.location.search);
                return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
            },
            decodeJwt(token) {
                const base64Payload = token.split(".")[1];
                const payloadBuffer = Buffer.from(base64Payload, "base64");
                return JSON.parse(payloadBuffer.toString());
            }
        }
    };
</script>
