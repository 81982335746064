<template>
    <div class="login-container">
        <div id="hero" class="tm-hero ">
            <section class="hero-section">
                <div class="hero-bg"></div>
            </section>
        </div>
        <div id="top">
            <div class="text-center">
                <section>
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-4 login p4-error">
                                <div class="modal-content">
                                    <div class="modal-heading">
                                        <img id="riskprologo" alt="Logo" src="/src/assets/images/riskpro.png">
                                    </div>
                                    <div class="modal-body">
                                        <div class="login__errors text-danger text-bold p4-error-text">
                                            {{error}}
                                        </div>
                                        <div class="fs-lg-0-9 mt-5 help-text text-muted">
                                            Please <a href="/p4login">try again</a>. If you need assistance, contact RiskPro at (949) 207-1044
                                            or email us at <a href="mailto:support@riskproadvisor.com">support@riskproadvisor.com</a>.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import queryString from 'query-string';
const { getInstance } = require('../../auth');

module.exports = {
    data() {
        return {
            error: 'There was an error authenticating the user.'
        }
    },
    mounted: function () {
        this.$nextTick(() => {
            const parsedHash = queryString.parse(window.location.search);
            console.log(parsedHash);
            this.init(this.auth0Logout);
        })
    },
    methods: {
        init(fn) {  // this is to ensure Auth0Client is available before trying to clear auth0 session
            let instance = getInstance();
            instance.$watch("loading", loading => {
                if (loading === false && instance.isAuthenticated) { // Auth0 logout only when session exists
                    fn(instance);
                }
            });
        },
        auth0Logout(authInstance) {
            const auth0Client = authInstance.auth0Client;
            // Remove auth0 cookies
            auth0Client.cookieStorage.remove(auth0Client.orgHintCookieName);
            auth0Client.cookieStorage.remove(auth0Client.isAuthenticatedCookieName);
            // Remove auth0 local storage
            authInstance.removeStorage();
            // Set isAuthenticated to false
            authInstance.resetIsAuthenticated();
        }
    }
}

</script>