var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "forgot-password-container" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "tm-top", attrs: { id: "tm-top" } }, [
        _c("div", { staticClass: "text-center" }, [
          _c("section", [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-4 forgot-password" }, [
                  _c("div", { staticClass: "modal-content" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _vm.succeed === false
                      ? _c("div", { staticClass: "modal-body mt-4" }, [
                          _c(
                            "form",
                            {
                              staticClass: "text-left",
                              attrs: { role: "form", method: "post" },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.onSubmit.apply(null, arguments)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "text-center fs-lg-0-9" },
                                [
                                  _vm._v(
                                    "\n                                            Please enter your User ID and Firm ID to reset your password.\n                                        "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "forgot-password__errors text-danger text-bold mt-2"
                                },
                                [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(_vm.error) +
                                      "\n                                        "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group label-floating mt-5",
                                  class: { "has-error": _vm.required_user }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.user_name,
                                        expression: "user_name"
                                      }
                                    ],
                                    ref: "username",
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "user_name",
                                      type: "text",
                                      name: "user name",
                                      value: ""
                                    },
                                    domProps: { value: _vm.user_name },
                                    on: {
                                      keyup: function($event) {
                                        return _vm.onChangeRemoveError()
                                      },
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.user_name = $event.target.value
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "user_name" }
                                    },
                                    [_vm._v("Enter User ID")]
                                  ),
                                  _vm._v(" "),
                                  _vm.required_user
                                    ? _c(
                                        "div",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            "User ID should not be\n                                                blank.\n                                            "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group label-floating",
                                  class: { "has-error": _vm.required_firm_id }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.firm_id,
                                        expression: "firm_id"
                                      }
                                    ],
                                    ref: "firmid",
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "firmid",
                                      type: "text",
                                      name: "firmid",
                                      value: ""
                                    },
                                    domProps: { value: _vm.firm_id },
                                    on: {
                                      keyup: function($event) {
                                        return _vm.onChangeRemoveError()
                                      },
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.firm_id = $event.target.value
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "firmid" }
                                    },
                                    [_vm._v("Enter Firm ID")]
                                  ),
                                  _vm._v(" "),
                                  _vm.required_firm_id
                                    ? _c(
                                        "div",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            "Firm ID should not be\n                                                blank.\n                                            "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _vm._m(2),
                              _vm._v(" "),
                              _vm._m(3)
                            ]
                          )
                        ])
                      : _c("div", { staticClass: "modal-body mt-4" }, [
                          _c("div", { staticClass: "text-center fs-lg-0-9" }, [
                            _vm._v(
                              "\n                                        Please check your email for the reset password link.\n                                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-cenetr mt-5" }, [
                            _vm._v(
                              "\n                                        Password reset information sent to:\n                                        "
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "font-weight-bold mt-2 text-muted"
                              },
                              [_vm._v(_vm._s(_vm.sentEmail))]
                            )
                          ]),
                          _vm._v(" "),
                          _vm._m(4)
                        ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("loader", { attrs: { showLoader: _vm.loading } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tm-hero", attrs: { id: "tm-hero" } }, [
      _c("section", [_c("div", { staticClass: "hero-bg" })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-heading" }, [
      _c("img", {
        attrs: {
          id: "riskprologo",
          alt: "Logo",
          src: "/src/assets/images/riskpro.png"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fs-lg-0-9 mt-5 help-text text-muted" }, [
      _vm._v(
        "\n                                            Need help with a User ID or Password? Contact RiskPro at (949) 207-1044\n                                            or email us at "
      ),
      _c("a", { attrs: { href: "mailto:support@riskproadvisor.com" } }, [
        _vm._v("support@riskproadvisor.com")
      ]),
      _vm._v(".\n                                        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-raised btn-primary btn-lg w-100",
              attrs: { type: "submit" }
            },
            [
              _vm._v(
                "SUBMIT\n                                                    "
              )
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "text-center fs-lg-0-9 mt-5 pt-5 help-text" },
      [
        _c("div", { staticClass: "mb-5 font-weight-bold" }, [
          _c("a", { attrs: { href: "/" } }, [_vm._v("RETURN TO LOGIN")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-muted text-left" }, [
          _vm._v(
            "\n                                            Need help with a User ID or Password? Contact RiskPro at (949) 207-1044 or\n                                            email us at "
          ),
          _c("a", { attrs: { href: "mailto:support@riskproadvisor.com" } }, [
            _vm._v("support@riskproadvisor.com")
          ]),
          _vm._v(".\n                                        ")
        ])
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-18cf1371", { render: render, staticRenderFns: staticRenderFns })
  }
}