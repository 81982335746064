<template>
    <div class="login-container">
        <loader :showLoader="true"></loader>
    </div>
</template>

<script>
import { getInstance } from '../../auth';

module.exports = {
    mounted() {
        // Redirect to P4 Auth0 login page for users coming from initiating site
        // Handled via router and authGuard
        this.init(this.handleP4Login);
        
    },
    methods: {
         init(fn) {  // this is to ensure Auth0Client is available before trying to fetch user details after authentication
            let instance = getInstance();
            instance.$watch("loading", loading => {
                if (loading === false) {
                    fn(instance);
                }
            });
        },
        handleP4Login() {
            window.location.href = '/p4callback';
        }
    }
};
</script>
