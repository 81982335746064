var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { attrs: { id: "top" } }, [
        _c("div", { staticClass: "text-center" }, [
          _c("section", [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-4 login" }, [
                  _c("div", { staticClass: "modal-content" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-body" }, [
                      _c(
                        "form",
                        {
                          staticClass: "text-left",
                          attrs: {
                            role: "form",
                            name: "loginForm",
                            id: "loginForm",
                            method: "post"
                          },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.handleSubmit.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "login__errors text-danger text-bold"
                            },
                            [
                              _vm._v(
                                "\n                                            " +
                                  _vm._s(_vm.error) +
                                  "\n                                        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "form-group label-floating",
                              class: { "has-error": _vm.required_user }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.user_name,
                                    expression: "data.user_name"
                                  }
                                ],
                                ref: "username",
                                staticClass: "form-control",
                                attrs: {
                                  id: "username",
                                  type: "text",
                                  name: "username",
                                  value: ""
                                },
                                domProps: { value: _vm.data.user_name },
                                on: {
                                  keyup: function($event) {
                                    return _vm.onChangeRemoveError()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.data,
                                      "user_name",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "username" }
                                },
                                [_vm._v("Enter User ID")]
                              ),
                              _vm._v(" "),
                              _vm.required_user
                                ? _c("div", { staticClass: "text-danger" }, [
                                    _vm._v("User ID should not be blank.")
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "form-group label-floating",
                              class: { "has-error": _vm.required_instance_id }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.instance_id,
                                    expression: "data.instance_id"
                                  }
                                ],
                                ref: "instanceid",
                                staticClass: "form-control",
                                attrs: {
                                  id: "instanceid",
                                  type: "text",
                                  name: "instanceid",
                                  value: ""
                                },
                                domProps: { value: _vm.data.instance_id },
                                on: {
                                  keyup: function($event) {
                                    return _vm.onChangeRemoveError()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.data,
                                      "instance_id",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "instanceid" }
                                },
                                [_vm._v("Enter Instance ID")]
                              ),
                              _vm._v(" "),
                              _vm.required_instance_id
                                ? _c("div", { staticClass: "text-danger" }, [
                                    _vm._v("Instance ID should not be blank.")
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "form-group label-floating",
                              class: { "has-error": _vm.required_password }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.password,
                                    expression: "data.password"
                                  }
                                ],
                                ref: "password",
                                staticClass: "form-control",
                                attrs: {
                                  id: "password",
                                  type: "password",
                                  name: "password",
                                  value: ""
                                },
                                domProps: { value: _vm.data.password },
                                on: {
                                  keyup: function($event) {
                                    return _vm.onChangeRemoveError()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.data,
                                      "password",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "password" }
                                },
                                [_vm._v("Enter Password")]
                              ),
                              _vm._v(" "),
                              _vm.required_password
                                ? _c("div", { staticClass: "text-danger" }, [
                                    _vm._v("Password should not be blank.")
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "row" }, [
                              _vm._m(2),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-xs-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "checkbox text-right" },
                                  [
                                    _c("label", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.data.remember_me,
                                            expression: "data.remember_me"
                                          }
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          name: "remember_me"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.data.remember_me
                                          )
                                            ? _vm._i(
                                                _vm.data.remember_me,
                                                null
                                              ) > -1
                                            : _vm.data.remember_me
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = _vm.data.remember_me,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.data,
                                                    "remember_me",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.data,
                                                    "remember_me",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.data,
                                                "remember_me",
                                                $$c
                                              )
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(
                                        "Remember Me\n                                                        "
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _vm._m(3)
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          attrs: {
                            id: "redirectForm",
                            action: _vm.redirectUrl,
                            target: "_self",
                            method: "POST"
                          }
                        },
                        [
                          _c("input", {
                            attrs: {
                              id: "jwtToken",
                              type: "hidden",
                              name: "jwt_token"
                            },
                            domProps: { value: _vm.jwtToken }
                          }),
                          _vm._v(" "),
                          _c("input", {
                            attrs: {
                              id: "rememberMe",
                              type: "hidden",
                              name: "remember_me"
                            },
                            domProps: { value: _vm.data.remember_me }
                          })
                        ]
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("loader", { attrs: { showLoader: _vm.loading } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tm-hero", attrs: { id: "hero" } }, [
      _c("section", { staticClass: "hero-section" }, [
        _c("div", { staticClass: "hero-bg" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-heading" }, [
      _c("img", {
        attrs: {
          id: "riskprologo",
          alt: "Logo",
          src: "/src/assets/images/riskpro.png"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-6" }, [
      _c("p", { staticClass: "help-link mt-2" }, [
        _c("a", { attrs: { href: "/help" } }, [_vm._v("Need login help?")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-raised btn-primary btn-lg w-100",
              attrs: { type: "submit" }
            },
            [
              _vm._v(
                "Login\n                                                    "
              )
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-308a83aa", { render: render, staticRenderFns: staticRenderFns })
  }
}