var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-container" }, [
    _c("div", { staticClass: "tm-top", attrs: { id: "tm-top" } }, [
      _c("div", { staticClass: "text-center" }, [
        _c("section", [
          _c("div", { staticClass: "container" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "modal-heading" }, [
                _c("div", [
                  _c("h1", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.error.title) + _vm._s(_vm.error.status))
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "modal-heading" }, [
                _c("div", [
                  _c("h4", { staticClass: "detail" }, [
                    _c("span", [_vm._v(_vm._s(_vm.error.code) + ": ")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.error.message))])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "modal-heading" }, [
        _c("img", {
          staticClass: "img-control",
          attrs: {
            id: "riskprologo",
            alt: "Logo",
            src: "/src/assets/images/riskpro.png"
          }
        })
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ec2e1b20", { render: render, staticRenderFns: staticRenderFns })
  }
}