var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.remoteFinished === false,
            expression: "remoteFinished === false"
          }
        ],
        staticClass: "reset-passward-container"
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading,
                expression: "!loading"
              }
            ],
            attrs: { id: "top" }
          },
          [
            _c("div", { staticClass: "text-center" }, [
              _c("section", [
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-4 reset-passward" }, [
                      _c("div", { staticClass: "modal-content" }, [
                        _vm._m(1),
                        _vm._v(" "),
                        _c("div", { staticClass: "modal-body" }, [
                          _c(
                            "form",
                            {
                              staticClass: "text-left",
                              attrs: {
                                role: "form",
                                "data-toggle": "validator",
                                method: "post"
                              },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.handleSubmit.apply(null, arguments)
                                }
                              }
                            },
                            [
                              _vm._m(2),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "text-center fs-lg-0-9 pb-3" },
                                [
                                  _vm._v(
                                    "\n                                                Please set your password\n                                            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-group label-floating has-feedback field-error"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "newpassword" }
                                    },
                                    [
                                      _vm._v(
                                        "Enter New\n                                                    Password"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  [_vm.passwordHidden ? "password" : "text"] ===
                                  "checkbox"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.newPassword,
                                            expression: "newPassword"
                                          }
                                        ],
                                        staticClass: "form-control mb-0",
                                        style: _vm.newPasswardUnderline,
                                        attrs: {
                                          id: "newpassword",
                                          name: "newpassword",
                                          value: "newPassword",
                                          "data-pattern-error": "errorMassage",
                                          type: "checkbox"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.newPassword
                                          )
                                            ? _vm._i(
                                                _vm.newPassword,
                                                "newPassword"
                                              ) > -1
                                            : _vm.newPassword
                                        },
                                        on: {
                                          focus: function($event) {
                                            return _vm.onNewPasswordFocusEventHandler()
                                          },
                                          keyup: function($event) {
                                            _vm.onCheckPasswordStrength()
                                            _vm.onPasswordMatch()
                                          },
                                          change: function($event) {
                                            var $$a = _vm.newPassword,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "newPassword",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.newPassword = $$a.concat(
                                                    [$$v]
                                                  ))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.newPassword = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.newPassword = $$c
                                            }
                                          }
                                        }
                                      })
                                    : [
                                        _vm.passwordHidden ? "password" : "text"
                                      ] === "radio"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.newPassword,
                                            expression: "newPassword"
                                          }
                                        ],
                                        staticClass: "form-control mb-0",
                                        style: _vm.newPasswardUnderline,
                                        attrs: {
                                          id: "newpassword",
                                          name: "newpassword",
                                          value: "newPassword",
                                          "data-pattern-error": "errorMassage",
                                          type: "radio"
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.newPassword,
                                            "newPassword"
                                          )
                                        },
                                        on: {
                                          focus: function($event) {
                                            return _vm.onNewPasswordFocusEventHandler()
                                          },
                                          keyup: function($event) {
                                            _vm.onCheckPasswordStrength()
                                            _vm.onPasswordMatch()
                                          },
                                          change: function($event) {
                                            _vm.newPassword = "newPassword"
                                          }
                                        }
                                      })
                                    : _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.newPassword,
                                            expression: "newPassword"
                                          }
                                        ],
                                        staticClass: "form-control mb-0",
                                        style: _vm.newPasswardUnderline,
                                        attrs: {
                                          id: "newpassword",
                                          name: "newpassword",
                                          value: "newPassword",
                                          "data-pattern-error": "errorMassage",
                                          type: [
                                            _vm.passwordHidden
                                              ? "password"
                                              : "text"
                                          ]
                                        },
                                        domProps: { value: _vm.newPassword },
                                        on: {
                                          focus: function($event) {
                                            return _vm.onNewPasswordFocusEventHandler()
                                          },
                                          keyup: function($event) {
                                            _vm.onCheckPasswordStrength()
                                            _vm.onPasswordMatch()
                                          },
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.newPassword =
                                              $event.target.value
                                          }
                                        }
                                      }),
                                  _vm._v(" "),
                                  _c("span", {
                                    class: [
                                      "glyphicon",
                                      _vm.passwordHidden
                                        ? "glyphicon-eye-close"
                                        : "glyphicon-eye-open"
                                    ],
                                    attrs: { "aria-hidden": "true" },
                                    on: {
                                      click: function($event) {
                                        _vm.passwordHidden = !_vm.passwordHidden
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: this.newPassword,
                                          expression: "this.newPassword"
                                        }
                                      ],
                                      staticClass: "strenth__meter col-md-12"
                                    },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-4 p-0" },
                                          [
                                            _c("div", {
                                              style: _vm.weakIndication
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-4 p-0" },
                                          [
                                            _c("div", {
                                              style: _vm.goodIndication
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-4 p-0" },
                                          [
                                            _c("div", {
                                              style: _vm.strongIndication
                                            })
                                          ]
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: this.newPassword,
                                          expression: "this.newPassword"
                                        }
                                      ],
                                      staticClass:
                                        "errors text-danger pt-2 fw-500"
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(_vm._s(_vm.errorMassage))
                                      ])
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-group label-floating has-feedback field-error pb-5"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "confirmpassward" }
                                    },
                                    [_vm._v("Confirm New Password")]
                                  ),
                                  _vm._v(" "),
                                  [
                                    _vm.confirmPasswordHidden
                                      ? "password"
                                      : "text"
                                  ] === "checkbox"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.confirmpassward,
                                            expression: "confirmpassward"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "confirmpassward",
                                          name: "confirmpassward",
                                          value: "confirmpassward",
                                          required: "",
                                          type: "checkbox"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.confirmpassward
                                          )
                                            ? _vm._i(
                                                _vm.confirmpassward,
                                                "confirmpassward"
                                              ) > -1
                                            : _vm.confirmpassward
                                        },
                                        on: {
                                          focus: function($event) {
                                            return _vm.onPasswordMatchFocusEventHandler()
                                          },
                                          keyup: function($event) {
                                            return _vm.onPasswordMatch()
                                          },
                                          change: function($event) {
                                            var $$a = _vm.confirmpassward,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = "confirmpassward",
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.confirmpassward = $$a.concat(
                                                    [$$v]
                                                  ))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.confirmpassward = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.confirmpassward = $$c
                                            }
                                          }
                                        }
                                      })
                                    : [
                                        _vm.confirmPasswordHidden
                                          ? "password"
                                          : "text"
                                      ] === "radio"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.confirmpassward,
                                            expression: "confirmpassward"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "confirmpassward",
                                          name: "confirmpassward",
                                          value: "confirmpassward",
                                          required: "",
                                          type: "radio"
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.confirmpassward,
                                            "confirmpassward"
                                          )
                                        },
                                        on: {
                                          focus: function($event) {
                                            return _vm.onPasswordMatchFocusEventHandler()
                                          },
                                          keyup: function($event) {
                                            return _vm.onPasswordMatch()
                                          },
                                          change: function($event) {
                                            _vm.confirmpassward =
                                              "confirmpassward"
                                          }
                                        }
                                      })
                                    : _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.confirmpassward,
                                            expression: "confirmpassward"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "confirmpassward",
                                          name: "confirmpassward",
                                          value: "confirmpassward",
                                          required: "",
                                          type: [
                                            _vm.confirmPasswordHidden
                                              ? "password"
                                              : "text"
                                          ]
                                        },
                                        domProps: {
                                          value: _vm.confirmpassward
                                        },
                                        on: {
                                          focus: function($event) {
                                            return _vm.onPasswordMatchFocusEventHandler()
                                          },
                                          keyup: function($event) {
                                            return _vm.onPasswordMatch()
                                          },
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.confirmpassward =
                                              $event.target.value
                                          }
                                        }
                                      }),
                                  _vm._v(" "),
                                  _c("span", {
                                    class: [
                                      "glyphicon",
                                      _vm.confirmPasswordHidden
                                        ? "glyphicon-eye-close"
                                        : "glyphicon-eye-open"
                                    ],
                                    attrs: { "aria-hidden": "true" },
                                    on: {
                                      click: function($event) {
                                        _vm.confirmPasswordHidden = !_vm.confirmPasswordHidden
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { class: _vm.match_indication_message },
                                    [
                                      _c("p", [
                                        _vm._v(_vm._s(_vm.match_message))
                                      ])
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "modal-footer" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-12" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-raised btn-primary btn-lg w-100",
                                        attrs: {
                                          type: "submit",
                                          disabled: !_vm.enabledBtn
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "SUBMIT\n                                                        "
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ])
                            ]
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c("loader", { attrs: { showLoader: _vm.loading } })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.remoteFinished,
            expression: "remoteFinished"
          }
        ],
        staticClass: "text-center mt-5",
        attrs: { id: "remoteMsg" }
      },
      [
        _vm._v(
          "\n        The Link is expired, Please contact your administrator.\n    "
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hero-container", attrs: { id: "hero" } }, [
      _c("section", { staticClass: "hero-section" }, [
        _c("div", { staticClass: "hero-bg" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-heading" }, [
      _c("img", {
        attrs: {
          id: "riskprologo",
          alt: "Logo",
          src: "/src/assets/images/riskpro.png"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login__errors" }, [
      _c("div", { staticClass: "pk-system-messages" })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f0de2618", { render: render, staticRenderFns: staticRenderFns })
  }
}