import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookie from 'vue-cookie';
import { Auth0Plugin } from './auth';
import Login from './components/Login/Login.component.vue';
import LoginAdmin from './components/Login/LoginAdmin.component.vue';
import Forgot from './components/ForgotPassward/Forgot.component.vue';
import ResetPassword from './components/ResetPassword/ResetPassword.component.vue';
import Loader from './components/Loader/Loader.component.vue';
import SOOLoader from './components/SSO/SsoLoader.component.vue';
import SOOView from './components/SSO/SsoView.component.vue';
import ErrorView from './components/Error/Error.component.vue';
import P4CallBack from './components/P4Callback/P4Callback.component.vue';
import Auth0Login from './components/P4Callback/Auth0.component.vue';
import P4ErrorView from './components/Error/P4Error.component.vue';

import authGuard from './auth/authGuard';

// eslint-disable-line no-unused-vars
require('./scss/style.scss');

Vue.use(VueRouter);
Vue.use(VueCookie);
Vue.component('loader', Loader);

const routesMap = {
    '/': Login,
    '/login': Login,
    '/help': Forgot,
    '/resetpassword': ResetPassword,
    '/admin': LoginAdmin,
    '/admin/login': LoginAdmin,
    '/sso-load': SOOLoader,
    '/sso-rpa': SOOView,
    '/error': ErrorView,
    '/p4callback': P4CallBack,
    '/p4login': Auth0Login,
    '/p4error': P4ErrorView
};

const routes = [
    { path: '/', component: Login },
    { path: '/login', component: Login },
    { path: '/help', component: Forgot },
    { path: '/resetpassword', component: ResetPassword },
    { path: '/admin', component: LoginAdmin },
    { path: '/admin/login', component: LoginAdmin },
    { path: '/sso-load', component: SOOLoader },
    { path: '/sso-rpa', component: SOOView, beforeEnter: authGuard },
    { path: '/error', component: ErrorView },
    { path: '/p4callback', component: P4CallBack, beforeEnter: authGuard },
    { path: '/p4login', component: Auth0Login, beforeEnter: authGuard },
    { path: '/p4error', component: P4ErrorView }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

Vue.use(Auth0Plugin, {
    domain: process.env.auth0_issuerBaseUrl,
    clientId: process.env.auth0_clientId,
    authorizationParams: {
        scope: 'openid profile email offline_access'
    },
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    redirectUri: `${process.env.baseUrl}p4callback`
})

new Vue({
    el: '#app',
    router,
    data: {
        currentRoute: window.location.pathname
    },
    computed: {
        ViewComponent() {
            return routesMap[this.currentRoute] || Login;
        }
    },
    render(param) {
        return param(this.ViewComponent);
    }
});

$.material.options.autofill = true;
$.material.init();
