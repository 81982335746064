<template>
    <div>
        <div class="reset-passward-container" v-show="remoteFinished === false">
            <div id="hero" class="hero-container">
                <section class="hero-section">
                    <div class="hero-bg"></div>
                </section>
            </div>
            <div id="top" v-show="!loading">
                <div class="text-center">
                    <section>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-4 reset-passward">
                                    <div class="modal-content">
                                        <div class="modal-heading">
                                            <img id="riskprologo" alt="Logo" src="/src/assets/images/riskpro.png">
                                        </div>

                                        <div class="modal-body">
                                            <form role="form" class="text-left" data-toggle="validator" @submit.prevent="handleSubmit" method="post">
                                                <div class="login__errors">
                                                    <div class="pk-system-messages"></div>
                                                </div>
                                                <div class="text-center fs-lg-0-9 pb-3">
                                                    Please set your password
                                                </div>
                                                <div class="form-group label-floating has-feedback field-error">
                                                    <label class="control-label" for="newpassword">Enter New
                                                        Password</label>
                                                    <input id="newpassword" v-bind:style="newPasswardUnderline" v-bind:type="[ passwordHidden ? 'password' : 'text']" class="form-control mb-0" name="newpassword" value="newPassword" v-model="newPassword" data-pattern-error="errorMassage" @focus="onNewPasswordFocusEventHandler()" @keyup="onCheckPasswordStrength();onPasswordMatch()">
                                                    <span v-bind:class="['glyphicon',passwordHidden ? 'glyphicon-eye-close' : 'glyphicon-eye-open']" aria-hidden="true" @click="passwordHidden = !passwordHidden"></span>

                                                    <div class="strenth__meter col-md-12" v-show="this.newPassword">
                                                        <div class="row">
                                                            <div class="col-md-4 p-0">
                                                                <div v-bind:style="weakIndication" />
                                                            </div>
                                                            <div class="col-md-4 p-0">
                                                                <div v-bind:style="goodIndication" />
                                                            </div>
                                                            <div class="col-md-4 p-0">
                                                                <div v-bind:style="strongIndication" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="errors text-danger pt-2 fw-500" v-show="this.newPassword">
                                                        <p>{{errorMassage}}</p>
                                                    </div>
                                                </div>
                                                <div class="form-group label-floating has-feedback field-error pb-5">
                                                    <label class="control-label" for="confirmpassward">Confirm New Password</label>
                                                    <input id="confirmpassward" v-bind:type="[ confirmPasswordHidden ? 'password' : 'text']" class="form-control" v-model="confirmpassward" name="confirmpassward" value="confirmpassward" required @focus="onPasswordMatchFocusEventHandler()" @keyup="onPasswordMatch()">
                                                    <span v-bind:class="['glyphicon', confirmPasswordHidden ? 'glyphicon-eye-close' : 'glyphicon-eye-open']" aria-hidden="true" @click="confirmPasswordHidden = !confirmPasswordHidden"></span>
                                                    <span v-bind:class="match_indication_message">
                                                        <p>{{match_message}}</p>
                                                    </span>
                                                </div>
                                                <div class="modal-footer">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <button type="submit" :disabled="!enabledBtn" class="btn btn-raised btn-primary btn-lg w-100">SUBMIT
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <loader :showLoader="loading"></loader>
        </div>
        <div id="remoteMsg" class="text-center mt-5" v-show="remoteFinished">
            The Link is expired, Please contact your administrator.
        </div>
    </div>
</template>
<script>
import zxcvbn from 'zxcvbn';
import passwordValidator from 'password-validator';
import axios from 'axios';

module.exports = {
    data() {
        return {
            remoteFinished: false,
            loading: false,
            reset_password_endpoint: '/api/v1/reset_password',
            SUCCESS_MESSAGE: 'Success! Passwords match.',
            NOT_MATCH_MESSAGE:
                    'Passwords do not match. Please enter same password.',
            match_message: '',
            errorMsg:
                    'Password must be at least 8 characters in length and include at least one: upper case character, lower case character, number, special character:`~!@#$%^*()_+-={}|[]:";?,./&.',
            errorMassage: '',
            weak_color: '#F4511E',
            good_color: '#FFC35F',
            strong_color: '#40B495',
            no_color: '#CFD8DC',
            padding: '2px',
            noIndication: {},
            weakIndication: {},
            goodIndication: {},
            strongIndication: {},
            match_indication: {},
            newPasswardUnderline: {},
            newPassword: '',
            confirmpassward: '',
            match_indication_message: {},
            enabledBtn: false,
            isValid: false,
            passwordHidden: true,
            confirmPasswordHidden: true
        };
    },
    mounted() {
        this.verifyEmailToken();
    },
    created() {
        this.resetToken = this.$route.query.token;

        if (!this.resetToken) {
            window.location.href = '/';
        }
    },
    methods: {
        verifyEmailToken() {
            this.loading = true;
            axios.get(`api/v1/session/verify_email_token?email_token=${this.resetToken}`).then((res) => {
                this.loading = false;
                const valid = JSON.stringify(res.data.response.detail.is_valid);
                if (!valid) {
                    JSON.stringify(res.data.response.detail.is_valid);
                    this.remoteFinished = true;
                }
            }).catch(() => {
                this.loading = false;
                this.remoteFinished = true;
            });
        },
        getPasswordSchema() {
            const schema = new passwordValidator();
            schema
                .is()
                .min(8) // Minimum length 8
                .is()
                .max(100) // Maximum length 100
                .has()
                .uppercase() // Must have uppercase letters
                .has()
                .lowercase() // Must have lowercase letters
                .has()
                .digits() // Must have digits
                .has()
                .symbols() // Must have one of the symbol
                .not()
                .spaces() // Should not have spaces
                .is()
                .not()
                .oneOf(['abc', 'Password123']); // Blacklist these values

            return schema;
        },
        onCheckPasswordStrength() {
            const { score } = zxcvbn(this.newPassword);
            if (score <= 1) {
                this.weakIndication = {
                    background: this.weak_color,
                    paddingRight: this.padding,
                    height: this.padding,
                };
                this.goodIndication = {
                    background: this.no_color,
                    paddingLeft: this.padding,
                    paddingRight: this.padding,
                    marginLeft: this.padding,
                    height: this.padding,
                };
                this.strongIndication = {
                    background: this.no_color,
                    height: this.padding,
                    paddingLeft: this.padding,
                    marginLeft: this.padding,
                };
            } else if (score > 1 && score < 4) {
                this.weakIndication = {
                    background: this.good_color,
                    paddingRight: this.padding,
                    height: this.padding,
                };
                this.goodIndication = {
                    background: this.good_color,
                    paddingLeft: this.padding,
                    marginLeft: this.padding,
                    height: this.padding,
                };
                this.strongIndication = {
                    background: this.no_color,
                    height: this.padding,
                    paddingLeft: this.padding,
                    marginLeft: this.padding,
                };
            } else if (score >= 4) {
                this.weakIndication = {
                    background: this.strong_color,
                    paddingRight: this.padding,
                    height: this.padding,
                };
                this.goodIndication = {
                    background: this.strong_color,
                    paddingLeft: this.padding,
                    paddingRight: this.padding,
                    marginLeft: this.padding,
                    height: this.padding,
                };
                this.strongIndication = {
                    background: this.strong_color,
                    height: this.padding,
                    paddingLeft: this.padding,
                    marginLeft: this.padding,
                };
            }
            if (Object.keys(this.weakIndication).length > 0) {
                this.newPasswordUnderline = { background: 0 };
            } else {
                this.newPasswordUnderline = {};
            }
            if (this.getPasswordSchema()
                .validate(this.newPassword)) {
                this.isValid = true;
                this.errorMassage = '';
            } else {
                this.isValid = false;
                this.errorMassage = this.errorMsg;
            }
        },
        onPasswordMatch() {
            if (
                this.newPassword === this.confirmpassward &&
                    this.newPassword.length > 0 &&
                    this.confirmpassward.length > 0 &&
                    this.isValid
            ) {
                this.match_message = this.SUCCESS_MESSAGE;
                this.enabledBtn = true;
                this.match_indication_message =
                        'errors text-success pull-right mt-2 fs-md-0-9 fw-500';
            } else if (this.isValid) {
                this.match_message = this.NOT_MATCH_MESSAGE;
                this.enabledBtn = false;
                this.match_indication_message =
                        'errors text-danger pull-right mt-2 fs-md-0-9 fw-500';
            }
        },
        onNewPasswordFocusEventHandler() {
            this.match_message = '';
        },
        onPasswordMatchFocusEventHandler() {
            this.match_message = '';
        },
        handleSubmit(e) {
            e.preventDefault();
            const csrf = this.$cookie.get('general-token');
            this.loading = true;

            const data = {
                action: 'PASSWORD_RESET',
                new_password: this.newPassword,
                reset_token: this.resetToken
            };

            axios.post(this.reset_password_endpoint, data, {
                headers: {
                    'csrf-token': csrf
                }
            }).then(() => {
                // todo: show successful message
                window.location.href = '/';
            }).catch((err) => {
                this.loading = false;
                toastr.clear();
                toastr.error(err.response.data.error || err.response.data.message || err.response.data.response.detail || err.response.statusText);
            });
        }
    },
};
</script>
<style lang="scss" scoped>
    @import 'reset.style.scss';
</style>
