<template>
    <div class="login-container">
        <div id="hero" class="tm-hero ">
            <section class="hero-section">
                <div class="hero-bg"></div>
            </section>
        </div>
        <div id="top">
            <div class="text-center">
                <section>
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-4 login">
                                <div class="modal-content">
                                    <div class="modal-heading">
                                        <img id="riskprologo" alt="Logo" src="/src/assets/images/riskpro.png">
                                    </div>

                                    <div class="modal-body">
                                        <form role="form" name="loginForm" id="loginForm" class="text-left"
                                              @submit.prevent="handleSubmit" method="post">
                                            <div class="login__errors text-danger text-bold">
                                                {{error}}
                                            </div>
                                            <div class="form-group label-floating" v-bind:class="{ 'has-error' : required_user }">
                                                <input id="username" ref="username" class="form-control" type="text" name="username"
                                                       v-model="data.user_name" value="" @keyup="onChangeRemoveError()">
                                                <label class="control-label" for="username">Enter User ID</label>
                                                <div v-if="required_user" class="text-danger">User ID should not be blank.</div>
                                            </div>
                                            <div class="form-group label-floating" v-bind:class="{ 'has-error' : required_instance_id }">
                                                <input id="instanceid" ref="instanceid" class="form-control" type="text"
                                                       name="instanceid" v-model="data.instance_id" value="" @keyup="onChangeRemoveError()">
                                                <label class="control-label" for="instanceid">Enter Instance ID</label>
                                                <div v-if="required_instance_id" class="text-danger">Instance ID should not be blank.</div>
                                            </div>
                                            <div class="form-group label-floating" v-bind:class="{ 'has-error' : required_password }">
                                                <input id="password" ref="password" class="form-control" type="password"
                                                       name="password" v-model="data.password" value="" @keyup="onChangeRemoveError()">
                                                <label class="control-label" for="password">Enter Password</label>
                                                <div v-if="required_password" class="text-danger">Password should not be blank.</div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-xs-6">
                                                        <p class="help-link mt-2">
                                                            <a href="/help">Need login help?</a>
                                                        </p>
                                                    </div>
                                                    <div class="col-xs-6">
                                                        <div class="checkbox text-right">
                                                            <label>
                                                                <input type="checkbox" name="remember_me" v-model="data.remember_me">Remember Me
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <button type="submit"
                                                                class="btn btn-raised btn-primary btn-lg w-100">Login
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                        <form id="redirectForm" :action="redirectUrl" target="_self" method="POST">
                                            <input id="jwtToken" type="hidden" name="jwt_token" :value="jwtToken">
                                            <input id="rememberMe" type="hidden" name="remember_me" :value="data.remember_me">
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <loader :showLoader="loading"></loader>
    </div>
</template>

<script>
const axios = require('axios');

module.exports = {
    data() {
        return {
            login_api_endpoint: '/api/v1/login',
            data: {
                user_name: '',
                password: '',
                firm_no: '1001',
                instance_id: '',
                remember_me: false
            },
            error: '',
            loading: false,
            jwtToken: '',
            redirectUrl: '',
            required_user: false,
            required_password: false,
            required_instance_id: false
        };
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            const csrf = this.$cookie.get('general-token');
            const me = this;
            if (me.data.user_name === '') {
                me.required_user = true;
                this.$refs.username.focus();
                return;
            }
            if (me.data.instance_id === '') {
                me.required_instance_id = true;
                this.$refs.instanceid.focus();
                return;
            }
            if (me.data.password === '') {
                me.required_password = true;
                this.$refs.password.focus();
                return;
            }
            this.loading = true;
            axios.post(this.login_api_endpoint, this.data, {
                headers: {
                    'csrf-token': csrf
                }
            }).then((res) => {
                me.jwtToken = JSON.stringify(res.data.response.detail);
                me.redirectUrl = me.getRedirectURL(res.data.response.detail.pk_redirection_url); // res.data.response.detail.pk_redirection_url + '/admin/login';
                setTimeout(() => {
                    document.getElementById('redirectForm').submit();
                }, 100);
            }).catch((err) => {
                me.loading = false;
                me.error = err.response.data.error || err.response.data.response.detail || err.response.statusText;
            });
        },
        onChangeRemoveError() {
            if (this.data.user_name !== '') {
                this.required_user = false;
            }
            if (this.data.instance_id !== '') {
                this.required_instance_id = false;
            }
            if (this.data.password !== '') {
                this.required_password = false;
            }
        },
        getRedirectURL(url) {
            let redirectURL = '';
            if (process.env.NODE_ENV === 'production') {
                redirectURL = `${url}/admin/login`;
            } else if (process.env.pk_redirection_url) {
                redirectURL = process.env.pk_redirection_url;
            }
            return redirectURL;
        }
    }
};
</script>
