<template>
    <div class="login-container">
        <loader :showLoader="!error"></loader>
        <h3 class="text-warning" v-show="error">{{ error }}</h3>
        <form id="redirectForm" :action="redirectUrl" target="_self" method="POST">
            <input id="jwtToken" type="hidden" name="jwt_token" aria-hidden="true" :value="jwtToken">
            <input id="rememberMe" type="hidden" name="remember_me" aria-hidden="true" :value="rememberMe">
        </form>
    </div>
</template>

<script>
import { getInstance } from '../../auth';

const axios = require('axios');

module.exports = {
    data() {
        return {
            jwtToken: '',
            redirectUrl: '',
            rememberMe: false,
            error: ''
        };
    },
    mounted() {
        this.init(this.handleP4Login);
    },
    methods: {
        init(fn) {  // this is to ensure Auth0Client is available before trying to fetch user details after authentication
            let instance = getInstance();
            instance.$watch("loading", loading => {
                if (loading === false) {
                    fn(instance);
                }
            });
        },
        async handleP4Login(authInstance) {
            const refreshToken = await authInstance.getRefreshToken();
            const user_name = authInstance.user.user_metadata.user_name;
            const firm_no = authInstance.user.app_metadata.firm_no;
            // await authInstance.removeStorage();
            axios.post(`/api/v1/p4login`,{  // Get JWT token to redirect into RPA
                user_name,
                firm_no,
            }).then((res) => {
                let detailResponse = res.data.response.detail;
                detailResponse = {
                    ...detailResponse,
                    auth0_refresh_token: refreshToken
                }
                this.jwtToken = JSON.stringify(detailResponse);
                this.redirectUrl = this.getRedirectURL(res.data.response.detail.pk_redirection_url);
                setTimeout(() => {
                    document.getElementById('redirectForm').submit();
                }, 100);
            }).catch((err) => {
                const errorData = err.response.data;
                window.location.href = `/p4error?error=${errorData.response.detail}`;
            })
        },
        getRedirectURL(url) {
            let redirectURL = '';
            if (process.env.NODE_ENV === 'production') {
                redirectURL = url;
            } else if (process.env.pk_redirection_url) {
                redirectURL = process.env.pk_redirection_url;
            }
            return redirectURL;
        }
    }
};
</script>
