var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c("loader", { attrs: { showLoader: !_vm.error } }),
      _vm._v(" "),
      _c(
        "h3",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.error,
              expression: "error"
            }
          ],
          staticClass: "text-warning"
        },
        [_vm._v(_vm._s(_vm.error))]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: {
            id: "redirectForm",
            action: _vm.redirectUrl,
            target: "_self",
            method: "POST"
          }
        },
        [
          _c("input", {
            attrs: {
              id: "jwtToken",
              type: "hidden",
              name: "jwt_token",
              "aria-hidden": "true"
            },
            domProps: { value: _vm.jwtToken }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: {
              id: "rememberMe",
              type: "hidden",
              name: "remember_me",
              "aria-hidden": "true"
            },
            domProps: { value: _vm.rememberMe }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0829e6be", { render: render, staticRenderFns: staticRenderFns })
  }
}